
const DEV_MODE = process.env.NODE_ENV !== 'production';

const societes = {
    BLANCREME: 'pro.blancreme.com',
    PICOURT_CABIS: 'pro.picourt-cabis.fr',
    TEST_PC: 'pro.picourt.wop-apps.ovh',
    TEST_BC: 'pro.blanccreme.wop-apps.ovh'
};

const HOST = window.location.hostname;

const BlancremeConfiguration = {
    URL_API: `https://${societes.BLANCREME}/api`,
    URL_GED: 'https://nextcloud.picourt-cabis.fr/blancreme',
    MANIFEST: '/blancreme/manifest.json',
    FAVICON: '/blancreme/favicon.png',
    TITLE: 'Pro | Blancrème',
    THEME: '#fff',
    PRIMARY: '#fca2a2',
    CODSOC: 101,
}

const PicourtCabisConfiguration = {
    URL_API: `https://${societes.PICOURT_CABIS}/api`,
    URL_GED: 'https://nextcloud.picourt-cabis.fr/picourt-cabis',
    MANIFEST: '/picourt-cabis/manifest.json',
    FAVICON: '/picourt-cabis/favicon.png',
    TITLE: 'Pro | Picourt-Cabis',
    THEME: '#fff',
    PRIMARY: '#997975',
    CODSOC: 1,
}

const LocalhostPCConfiguration = {
    URL_API: `http://localhost:3011/api`,
    URL_GED: 'https://nextcloud.picourt-cabis.fr/picourt-cabis',
    MANIFEST: '/picourt-cabis/manifest.json',
    FAVICON: '/picourt-cabis/favicon.png',
    TITLE: 'TEST Pro | Picourt-Cabis',
    THEME: '#fff',
    PRIMARY: '#997975',
    CODSOC: 1,
}


const LocalhostBCConfiguration = {
    URL_API: `http://localhost:3011/api`,
    URL_GED: 'https://nextcloud.picourt-cabis.fr/blancreme',
    MANIFEST: '/picourt-cabis/manifest.json',
    FAVICON: '/picourt-cabis/favicon.png',
    TITLE: 'TEST Pro | BC',
    THEME: '#fff',
    PRIMARY: '#fca2a2',
    CODSOC: 101,
}


const BlancremeTESTConfiguration = {
    URL_API: `https://${societes.TEST_BC}/api`,
    URL_GED: 'https://nextcloud.picourt-cabis.fr/blancreme',
    MANIFEST: '/blancreme/manifest.json',
    FAVICON: '/blancreme/favicon.png',
    TITLE: 'TEST - Pro | Blancrème',
    THEME: '#fff',
    PRIMARY: '#fca2a2',
    CODSOC: 101,
}

const PicourtTESTConfiguration = {
    URL_API: `https://${societes.TEST_PC}/api`,
    URL_GED: 'https://nextcloud.picourt-cabis.fr/picourt-cabis',
    MANIFEST: '/picourt-cabis/manifest.json',
    FAVICON: '/picourt-cabis/favicon.png',
    TITLE: 'TEST - Pro | Picourt-Cabis',
    THEME: '#fff',
    PRIMARY: '#997975',
    CODSOC: 1,
}

// Apply the choosen config
const applyConfigurations = (ConfigurationObject) => {

    const manifestEl = document.getElementById('manifest');
    const faviconEl = document.getElementById('favicon');
    const titleEl = document.getElementById('title');
    const themeEl = document.getElementById('theme');
    
    manifestEl.setAttribute('href', ConfigurationObject.MANIFEST);
    faviconEl.setAttribute('href', ConfigurationObject.FAVICON);
    titleEl.innerHTML = ConfigurationObject.TITLE;
    themeEl.setAttribute('content', ConfigurationObject.THEME);
}

const getConfiguration = () => {
    switch (HOST) {
        case societes.BLANCREME:
            return BlancremeConfiguration;
        case societes.PICOURT_CABIS:
            return PicourtCabisConfiguration;
        case societes.TEST_BC:
            return BlancremeTESTConfiguration;
        case societes.TEST_PC:
            return PicourtTESTConfiguration;
        case "localhost": 
            return LocalhostBCConfiguration;
    }
}

const configuration = getConfiguration();

applyConfigurations(configuration);

export default configuration;