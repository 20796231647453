import React, { useEffect, useState } from "react";
import theme from "../ui/theme";
import Header from "./Header";
import Button from "../ui/Buttons";
import Text from "../ui/Text";
import Drawer from "@material-ui/core/Drawer";
import { Container } from "react-bootstrap";
import { useAuth } from "../Auth/useAuth";
import { useCart } from "../Carts/useCart";
import CurrentCart from "../Carts/CurrentCart";
import useTranslations from "../Translations/useTranslations";
import BrandLogo from "../ui/BrandLogo";
import ClientLogo from "../ui/client-logo";
import { ListItem, ListItemText, Box, Divider } from "@material-ui/core";
import { ucfirst, isMobile, price } from "../../utils";
import SearchArticles from "../Articles/SearchArticles";

function Navigation({ items, activeItem, selectItem, set_search_criteria }) {
  const trl = useTranslations();
  const [{ user }] = useAuth();
  const [{ current_cart }, { getCartData }] = useCart(user.id);
  const [title, updateTitle] = useState(
    trl.data.message("FAG", "CODFAG", activeItem.CODFAG, "LIBFAG")
  );

  useEffect(() => {
    console.log("effect navigation");
    updateTitle(trl.data.message("FAG", "CODFAG", activeItem.CODFAG, "LIBFAG"));
  }, [activeItem]);

  const [cartModalOpened, setCartModalOpened] = useState(false);
  const [catalogueModalOpen, setCatalogueModalOpen] = useState(false);

  const onSearchCriteraChange = (text) => {
    text
      ? updateTitle('Recherche pour "' + text + '"')
      : updateTitle(
          trl.data.message("FAG", "CODFAG", activeItem.CODFAG, "LIBFAG")
        );
    set_search_criteria(text);
  };

  return (
    <div style={{ display: "unset" }}>
      <Header
        fixed={true}
        left_pane={
          <div className={"d-flex align-items-center"}>
            <Box flex={1}>
              <BrandLogo />
            </Box>
            {!isMobile() && (
              <Box flex={1}>
                <Text
                  variant="title_2"
                  style={{
                    flex: 1,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {current_cart.client?.NOMCLI}
                </Text>
              </Box>
            )}
            <Box flex={1}>
              <ClientLogo userId={current_cart.client?.CODCLI} />
            </Box>
          </div>
        }
        right_pane={
          <div className={"d-flex align-items-center"}>
            <Button
              size="mini"
              label={trl.message("MainPage.FagList")}
              style={{ marginRight: ".5rem" }}
              onClick={() => setCatalogueModalOpen(true)}
            />
            <Button
              disabled={!document.getElementById("open-filters")}
              size="mini"
              label={trl.message("MainPage.filtersAction")}
              style={{ marginRight: ".5rem" }}
              onClick={() => {
                const btn = document.getElementById("open-filters");
                if (btn) {
                  btn.click();
                }
              }}
            />
            {/* display catalogue => <div className='mr-3'>
                            <div className='d-flex' style={{padding: theme.spacing(.25)}}>
                                {
                                    items && items.map((fag) => (
                                        <button style={(activeItem.CODFAG === fag.CODFAG) ? {...style, backgroundColor: theme.primary, color: 'white', borderRadius: 3} : style} key={fag.CODFAG} onClick={() => selectItem(fag)}>
                                            {trl.data.message('FAG', 'CODFAG', fag.CODFAG, 'LIBFAG')}
                                        </button>
                                    ))
                                }
                            </div>
                        </div> */}
            <Button
              size="mini"
              icon="shopping-cart"
              label={(() => {
                const { mt_total, mt_total_remise_pack, cumul_plv_in_cart } =
                  getCartData();
                return price(
                  mt_total - mt_total_remise_pack - cumul_plv_in_cart
                );
              })()}
              onClick={() => setCartModalOpened(true)}
            />
          </div>
        }
      />

      <Header
        left_pane={
          <Container className="mt-2">
            <div>
              <Text variant="title">{title}</Text>
            </div>
          </Container>
        }
        right_pane={
          <SearchArticles
            placeholder={trl.message("MainPage.searchInputPlaceholder")}
            onChange={(t) => onSearchCriteraChange(t)}
          />
        }
      />

      <Drawer
        open={cartModalOpened}
        anchor="right"
        onClose={() => setCartModalOpened(false)}
      >
        <CurrentCart closeCart={() => setCartModalOpened(false)} />
      </Drawer>
      <Drawer
        anchor="right"
        open={catalogueModalOpen}
        onClose={() => setCatalogueModalOpen(false)}
      >
        <Box p={3}>
          <Text
            as="div"
            variant="title"
            style={{
              width: "100%",
              textAlign: "center",
              borderBottom: "3px solid " + theme.primary,
              paddingBottom: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          >
            {trl.message("NavigationMenu.title")}
          </Text>
        </Box>
        {items &&
          items.map((fag) => (
            <ListItem
              style={{ minWidth: "250px" }}
              key={fag.CODFAG}
              button
              onClick={() => {
                selectItem(fag);
                setCatalogueModalOpen(false);
              }}
              selected={activeItem.CODFAG === fag.CODFAG}
            >
              <ListItemText
                primary={ucfirst(
                  trl.data.message("FAG", "CODFAG", fag.CODFAG, "LIBFAG")
                )}
              />
            </ListItem>
          ))}
        <Divider />
      </Drawer>
    </div>
  );
}

export default Navigation;
