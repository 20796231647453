import { useDispatch, useSelector } from 'react-redux';
import * as actions from './actions';
import sdk from '../../sdk/sdk';
import moment from 'moment';
import { round2 } from '../../utils';
import {getPricesSteps} from '../../utils_price';

export function useCart (id) {

    const dispatch = useDispatch();
    const { carts } = useSelector(state => ({
        carts: state.carts[id],
    }));

    const methods = {
        createCart: (client) => dispatch({type: actions.CREATE_CART, payload: client}),
        selectCart: (idCart) => dispatch({type: actions.SELECT_CART, payload: idCart}),
        addInCart: (gen_products) => dispatch({type: actions.ADD_IN_CART, payload: gen_products}),
        addPackInCart: (payload) => dispatch({type: actions.ADD_PACK_IN_CART, payload}),
        removePackFromCart: (payload) => dispatch({type: actions.REMOVE_PACK_FROM_CART, payload}),
        customRemise: (creator, libgen, codart, remise) => dispatch({type: actions.CUSTOM_REMISE, payload: {creator, libgen, codart, remise}}),
        createHeader: (client) => dispatch({type: actions.CREATE_HEADER, payload: client}),
        changeFeetRemise: (payload) => dispatch({type: actions.CHANGE_FEET_REMISE, payload }),
        removeOrderHeader: (payload) => dispatch({type: actions.REMOVE_ORDER_HEADER, payload }),
        removeCart: (payload) => dispatch({ type: actions.REMOVE_CART, payload}),
        removeCartFromCartList: (payload) => dispatch({ type: actions.REMOVE_CART_FROM_CART_LIST, payload}),
        getCartData: () => getCartData(carts.current_cart.products, carts.current_cart.packs),
        getMaxMtPLVOffert: (data) => getMaxMtPLVOffert(data, carts.current_cart.client),
        generateOrder: (creator, cb) => {
            const order = generateOrder(carts.current_cart);
            sdk.orders.create(order).then(data => {
                if (data.success) {
                    dispatch({type: actions.GENERATE_ORDER_SUCCESS, payload: { creator, generated_at: moment().format('DD/MM/YYYY'), email: data.payload.mail_sent?.email || "email non renseigné" }})
                }
                if (cb) cb(null, data.payload);
            })
            .catch(error => {
                console.log(error);
                if (cb) cb(error, null);
            })
        },
        changeHeader: (key, value) => dispatch({type: actions.CHANGE_CART_HEADER, payload: {
            creator: id,
            key,
            value,
        }}),
    };

    return [carts, methods];
}

function getCartData(products, packs) {
    const mt_total_remise_pack = packs.reduce((acc, pack) => acc + (pack.qte_commande * pack.mt_remise), 0);

    return Object.keys(products).reduce((acc, genName) => {
        let articles = products[genName];
        articles.forEach(art => {
            let pub = art.article.PUB;
            let price = art.qte_commande * pub;

            if (art.custom_remise >= 0) {
                price = art.qte_commande * round2((pub * (1 - (art.custom_remise / 100))));
            }
            
            if (art.article.gen_in_cumul === 'O') {
                acc.cumul_ca_plv += round2(price);
            }

            if (art.article.TESTEUR_ou_PLV !== '')
            {
                acc.cumul_plv_in_cart += round2(price);
            }

            if (art.article.prices) {
                const {priceStep, nbUnit} =  getPricesSteps(art.article, art.qte_commande)
                acc.mt_total += round2(priceStep.price * art.qte_commande / nbUnit);
            }
            else {
                acc.mt_total += round2(price);
            }
            
        });
        //
        return acc;
    }, {cumul_ca_plv: 0, mt_total: 0, cumul_plv_in_cart: 0, mt_total_remise_pack: mt_total_remise_pack});
}

const getMaxMtPLVOffert = (data, client) => {
    //
    let mt_remise = 0;
    if (data.cumul_ca_plv > client.groupe_tarifaire.MT_CDC_SEUIL4)
    {
        mt_remise = (data.cumul_ca_plv * (client.groupe_tarifaire.REMISE_PLV_TESTEUR4 / 100));
    } else if (data.cumul_ca_plv > client.groupe_tarifaire.MT_CDC_SEUIL3)
    {
        mt_remise = (data.cumul_ca_plv * (client.groupe_tarifaire.REMISE_PLV_TESTEUR3 / 100));
    } else if (data.cumul_ca_plv > client.groupe_tarifaire.MT_CDC_SEUIL2)
    {
        mt_remise = (data.cumul_ca_plv * (client.groupe_tarifaire.REMISE_PLV_TESTEUR2 / 100));
    } else if (data.cumul_ca_plv > client.groupe_tarifaire.MT_CDC_SEUIL1)
    {
        mt_remise = (data.cumul_ca_plv * (client.groupe_tarifaire.REMISE_PLV_TESTEUR1 / 100));
    }

    return Math.round(mt_remise * 100) / 100;
}

const generateOrder = (current_cart) => {

    const { client, header, products, packs } = current_cart;

    let order = {};

    if (header.PROSPECT !== 'O') {
        // This client is not a PROSPECT
        order.header = header;
    } else {
        // Generate PROSPECT header ORDER
        order.header = {
            CODCLI: header.CODCLI,
            PROSPECT: header.PROSPECT,
            PROSPECT_NOM: header.PROSPECT_NOM,
            PROSPECT_PRENOM: header.PROSPECT_PRENOM,
            PROSPECT_EMAIL: header.PROSPECT_EMAIL,
            PROSPECT_ADR_LIV: header.PROSPECT_ADR_LIV,
            PROSPECT_ADR_FAC: header.PROSPECT_ADR_FAC,
            MT_REMISE: header.MT_REMISE,
            MT_PORT: header.MT_PORT,
        };
    }

    // Create body ITEMS order
    order.items = Object.keys(products).reduce((items, genName) => {
        const genProducts = products[genName];
        const Pack = packs.find(p => p.name === genName); 
        const isPack = !!Pack;

        genProducts.map(product => {

            // Determinate REMISE
            const remise = product.custom_remise >= 0 ? product.custom_remise : ((product.article.REMISE_FIXE > 0) 
            ? product.article.REMISE_FIXE
            : ((product.article.groupe_tarifaire.REMISE_FIXE > 0) 
                ? product.article.groupe_tarifaire.REMISE_FIXE
                : 0 ));

            items.push({
                CODART: product.article.CODART,
                PUB: product.pub,
                PUB_BASE_ARTICLE: product.article.PUB,
                REMISE: remise,
                QTE: product.qte_unite_de_vente,
                QTE_UNITAIRE: product.qte_commande,
                CODGEN: product.article.CODGEN,
                // Pack data
                DANS_PACK: isPack ? 'O' : 'N',
                MT_REMISE_PACK: isPack ? Pack.mt_remise : 0,
                QTE_PACK: isPack ? Pack.qte_commande : 0,
            });
        });
        
        return items;
    }, []);

    return order;
};