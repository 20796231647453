import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '../routes';
import { main } from '../routes/main';
import theme from '../components/ui/theme';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../components/ui/Buttons';
import Text from '../components/ui/Text';
import { admin } from '../routes/admin';
import Header from '../components/Headers/Header';
import { useAuth } from '../components/Auth/useAuth';
import confs from '../configurations';
import { useCart } from '../components/Carts/useCart';
import { useAPIManager } from '../components/lib/APIManager';
import { Drawer, Box, Typography, Dialog, Tooltip } from '@material-ui/core';
import CartsList from '../components/Carts/CartsList';
import useTranslations from '../components/Translations/useTranslations';
import BrandLogo from '../components/ui/BrandLogo';
import ClientLogo from '../components/ui/client-logo';


const { URL_API, URL_GED } = confs;

function Main() {

    const dispatch = useDispatch();

    const [auth, authMethods] = useAuth();
    const [carts, cartsMethods] = useCart(auth.user.id);
    const CODGRT = auth.user.codgrt;

    const trl = useTranslations();

    const [isDrawerCartsOpen, setDrawerCartsOpen] = useState();
    
    const { user } = useSelector(state => ({
        user: state.auth && state.auth.user,
    }));

    const [isCreatingCart, setCartCreationProcessing] = useState();

    const _startCart = (client) => {
        dispatch({type: 'start_cart', payload: client});
        navigate.push(main.APP_NAVIGATION);
    };

    const styles = {
        outterContainer: {
            borderRadius: '3px',
        },
        innerContainer: {
            padding: '1.5rem',
        },
    };

    const [clients, clientsMethods] = useAPIManager({
        baseUrl: URL_API,
        ressource: {
            name: 'clients',
            key: 'CODCLI',
        },
        defaultHeaders: {
            'Content-type': 'application/json',
            'Authorization': state => state.auth && 'Bearer '+state.auth.token,
        }
    });




    return (
        <div style={{minHeight: 'calc(100vh - 1.5rem)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{position: 'fixed', top: 0, left: 0, width: '100%'}}>
                <Header 
                    left_pane={(
                        <div className='d-flex align-items-center'>
                            <BrandLogo />
                            <Text as='span' variant='title'>
                                {trl.message('HomePage.title', { username: decodeURIComponent(escape(user.data.name)) })}
                            </Text>

                            {console.log("---",user.id)}
                            <ClientLogo userId={user.id}/>
                        </div>
                    )}
                    right_pane={(
                        <div className='d-flex justify-content-center'>
                            {
                                auth.user.role !== 'VRP' && (
                                    <React.Fragment>
                                        <Tooltip title={trl.message('MainPage.Link.priceLabel')}>
                                            <div>
                                                <Button
                                                    className='mr-1'
                                                    size='mini'
                                                    onClick={() => window.open(`${URL_GED}/Edoc/${CODGRT}/tarif${CODGRT}.pdf`)}
                                                    icon='tag'
                                                />
                                            </div>
                                        </Tooltip>
                                        <Tooltip title={trl.message('MainPage.Link.imageBankLabel')}>
                                            <div>
                                                <Button
                                                    className='mr-1'
                                                    size='mini'
                                                    onClick={() => window.open(`https://drive.google.com/drive/folders/1lztKfla-WKEGC1BRQDFZ68S-ZxFYcDyS?usp=sharing`)}
                                                    icon='picture-o'
                                                />
                                            </div>
                                        </Tooltip>
                                        <Tooltip title={trl.message('MainPage.Link.brandToolsLabel')}>
                                            <div>
                                                <Button
                                                    className='mr-1'
                                                    size='mini'
                                                    onClick={() => window.open(`https://drive.google.com/drive/folders/1gskGY0EP8HDdVJxrJU9O3S0giYymoDwl?usp=sharing`)}
                                                    icon='cloud-download'
                                                />
                                            </div>
                                        </Tooltip>

                                        <Tooltip title={trl.message('MainPage.Link.informationLabel')}>
                                            <div>
                                                <InformationLink vrpInfos={auth.user.data.vrp} />
                                            </div>
                                        </Tooltip>

                                        <div
                                            style={{
                                                border: 'none',
                                                borderLeft: '1px solid rgba(0, 0, 0, .5)',
                                                height: '100%',
                                                minHeight: '1px',
                                                paddingRight: '.5rem',
                                                width: '1px',  
                                            }}
                                        />

                                    </React.Fragment>
                                )
                            }
                            <Button
                                size='mini'
                                icon='power-off'
                                onClick={() => authMethods.logout()}
                            />
                        </div>
                    )}
                />
            </div>
            <Container>
                <div style={styles.outterContainer}>
                    <div style={styles.innerContainer}>
    
                        <Row>
                            <Col md={6} style={{borderRight: '1px solid rgba(0, 0, 0, .125)'}}>
                                <div style={theme.box_center}>
                                    <div className='text-center'>
                                        <Text as='p' variant='body'>
                                            {
                                                carts  && carts.carts.length > 0 
                                                ? trl.message('HomePage.pendingCarts', { pendingCarts: carts.carts.length })
                                                : trl.message('HomePage.noPendingCarts')
                                            }
                                            
                                        </Text>
                                        {
                                            (carts && carts.carts.length > 0) &&
                                                <Button
                                                    label={trl.message('HomePage.pendingCartsAction')}
                                                    onClick={() => setDrawerCartsOpen(true)}
                                                />
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div style={theme.box_center}>
                                    <div className='text-center'>
                                        <Text as='p' variant='body'>
                                            {trl.message('HomePage.newCart')}
                                        </Text>
                                        <Button
                                            icon='shopping-cart'
                                            label={trl.message('HomePage.newCartAction')}
                                            onClick={() => {
                                                if (auth.user.role === 'VRP') {
                                                    navigate.push(admin.NEW_CART);
                                                } else {
                                                    if (!isCreatingCart) {
                                                        setCartCreationProcessing(true)
                                                        clientsMethods.getItem(user.id, null, (err, item) => {
                                                            console.log('item', item);
                                                            cartsMethods.createCart({client: item, creator: item?.CODCLI});
                                                            navigate.push(admin.APP_NAVIGATION, {critere: 'start'});
                                                        });
                                                    }
                                                }
                                            }}
                                        />

                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <Drawer open={isDrawerCartsOpen} anchor='left' onClose={() => setDrawerCartsOpen(false)}>
                <CartsList />
            </Drawer>

        </div>
    );
}


const InformationLink = ({ vrpInfos }) => {

    const trl = useTranslations();
    const [open, isOpen] = useState(false);
    
    return (
        <React.Fragment>
            <Button
                className='mr-1'
                size='mini'
                icon='user'
                onClick={() => isOpen(true)}
            />
            <Dialog
                open={open} onClose={() => isOpen(false)}
            >
                <Box p={3}>
                    <Typography color='primary' component='p' variant='body'>
                        {trl.message('HomePage.Dialog.contactNameLabel')}
                    </Typography>
                    <Typography component='p' variant='body'>
                        {vrpInfos.NOMOPE}: <a href={`mailto:${vrpInfos.EMAIL}`}>{vrpInfos.EMAIL}</a>, <a href={`tel:${vrpInfos.TEL}`}>{vrpInfos.TEL}</a>
                    </Typography>
{/*                     
                    <Typography color='primary' component='p' variant='body'>
                        {trl.message('HomePage.Dialog.contactEmailLabel')}
                    </Typography>
                    <Typography component='p' variant='body'>
                        {vrpInfos.EMAIL}
                    </Typography> */}
                </Box>
            </Dialog>
        </React.Fragment>
    );
}
 
export default Main;