import React from "react";
import { useCart } from "./useCart";
import { useAuth } from "../Auth/useAuth";
import Text from "../ui/Text";
import theme from "../ui/theme";
import Button from "../ui/Buttons";
import { navigate } from "../../routes";
import { admin } from "../../routes/admin";
import moment from "moment";
import useTranslations from "../Translations/useTranslations";

const style = {
  date: {
    fontSize: ".8rem",
    color: "#adadad",
    marginLeft: theme.spacing(1.5),
  },
};

function CartsList() {
  const [{ user }] = useAuth();
  const trl = useTranslations();

  const [carts, { removeCart, selectCart }] = useCart(user.id);

  const _removeCart = (_id) => {
    if (!window.confirm(trl.message("HomePage.Dialog.deleteCart"))) return;

    removeCart({ creator: user.id, _id });
  };

  const _selectCart = (_id) => {
    selectCart({ creator: user.id, _id });
    navigate.push(admin.APP_NAVIGATION, { critere: "start" });
  };

  return (
    <React.Fragment>
      {carts &&
        carts.carts &&
        carts.carts
          .sort((item1, item2) => {
            const item1Date = moment(item1.created_at);
            const item2Date = moment(item2.created_at);
            // Order by datetime desc
            if (item1Date.isAfter(item2Date)) return -1;
            if (item2Date.isAfter(item1Date)) return 1;

            return 0;
          })
          .map((cart) => (
            <div key={cart._id} style={{ padding: theme.spacing([1.5, 2]) }}>
              <Text
                as="div"
                variant="title2"
                className="d-flex justify-content-between"
              >
                {cart.client?.NOMCLI}

                <Text as="span" variant="subtitle" style={style.date}>
                  {moment(cart.created_at).format("L")}
                </Text>
              </Text>
              <Text as="p" variant="subtitle">
                {cart.client?.CODCLI}
              </Text>
              <div className="d-flex justify-content-between">
                <Button
                  size="mini"
                  className="mr-3"
                  label={trl.message("HomePage.resumeCartAction")}
                  onClick={() => _selectCart(cart._id)}
                />
                <Button
                  size="mini"
                  icon="trash"
                  onClick={() => _removeCart(cart._id)}
                />
              </div>
            </div>
          ))}
    </React.Fragment>
  );
}

export default CartsList;
