import React, { useRef, useEffect } from 'react';
import Header from '../components/Headers/Header';
import Button from '../components/ui/Buttons';
import Text from '../components/ui/Text';
import { navigate } from '../routes';
import { Container, Row, Col } from 'react-bootstrap';
import confs from '../configurations';
import theme from '../components/ui/theme';
import { useState } from 'reinspect';
import { admin } from '../routes/admin';
import { useCart } from '../components/Carts/useCart';
import { useAuth } from '../components/Auth/useAuth';
import { useAPIManager } from '../components/lib/APIManager';
import FSLoader from '../components/Loaders/FSLoader';
import useTranslations from '../components/Translations/useTranslations';
import BrandLogo from '../components/ui/BrandLogo';

const { URL_API } = confs;

function ChooseClientPage () {
    const searchBarRef = useRef(null);
    const [loadingCli, setLoadingCli] = useState(false);

    const [auth, authMethods] = useAuth();
    const [carts, cartsMethods] = useCart(auth.user.id);
    const [clients, clientsMethods] = useAPIManager({
        baseUrl: URL_API,
        ressource: {
            name: 'clients',
            key: 'CODCLI',
        },
        defaultHeaders: {
            'Content-type': 'application/json',
            'Authorization': state => state.auth && 'Bearer '+state.auth.token,
        }
    });

    const [loading, isLoading] = useState(false);

    const trl = useTranslations();
    
    useEffect(() => {
        if (searchBarRef.current) {
            searchBarRef.current.focus();
        }
    }, [ searchBarRef ]);

    useEffect(() => {
        isLoading(true);
        clientsMethods.getCollection(null, () => isLoading(false));
    }, [ ]);
    
    return (
        <div>
            <Header 
                fixed={true}
                left_pane={(
                    <div className='d-flex align-items-center'>
                        <BrandLogo />
                        <Button
                            size='mini'
                            icon='angle-left'
                            onClick={() => navigate.goBack()}
                        />
                        <Text variant='title' className='ml-3'>
                            {trl.message('ChooseClientPage.title')}
                        </Text>
                    </div>
                )}
            />
            <Header />

            <Container className='mt-3'>

                <div className='my-2'>
                    <div className='mb-5'>
                        <Row className='justify-content-center'>
                            <Col md={8}>
                                <SearchBar
                                    ref={searchBarRef}
                                    onChange={(e) => clientsMethods.filterCollection(e.target.value, ['CODCLI', 'NOMCLI', 'VILLE', 'CPOSTAL'])}
                                    placeholder={trl.message('ChooseClientPage.searchInputPlaceholder')}
                                />
                            </Col>
                        </Row>
                    </div>
                    <Row className='justify-content-center'>
                        {(!clients.collection && loading) && <FSLoader />}
                        {
                            clients && clients.filter_results && clients.filter_results.map((client, key) => (
                                <Col md={8} key={key}>
                                    { key !== 0 && <hr />}
                                    <div key={client.CODCLI} className='d-flex justify-content-between align-items-center'>
                                        <Text as='div' variant='title_2'>
                                            {client.NOMCLI}
                                            <Text as='div'>{client.CODCLI} - {client.CPOSTAL} {client.VILLE}</Text>
                                        </Text>
                                        <div className='d-flex align-items-center'>
                                            {
                                                carts && carts.carts.filter(cart => cart.client?.CODCLI === client.CODCLI).length > 0 &&
                                                    <Text variant='title' className='mr-3'>
                                                        {carts.carts.filter(cart => cart.client?.CODCLI === client.CODCLI).length}
                                                    </Text>
                                            }
                                            <Button
                                                size='mini'
                                                icon='cart-plus'
                                                onClick={() => {
                                                    setLoadingCli(client.CODCLI);
                                                    isLoading(true);
                                                    clientsMethods.getItem(client.CODCLI, null, (err, _client) => {
                                                        cartsMethods.createCart({client: _client, creator: auth.user.id});
                                                        navigate.push(admin.APP_NAVIGATION, { critere: 'start' });
                                                        isLoading(false);
                                                    });
                                                }}
                                                loading={loading && loadingCli === client.CODCLI}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </div>

            </Container>
        </div>
    );
}

const SearchBar = React.forwardRef((props, ref) => {
    return (
        <div className='d-flex align-items-center' style={{
                ...theme.neumorphicOutset,
                borderRadius: 100,
                padding: theme.spacing(.75),
            }}
        >
            <i className='fa fa-search mx-2'></i>
            <input 
                {...props}
                ref={ref}
                type='search'
                style={{
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    backgroundColor: 'transparent'
                }}
            />
        </div>
    );
});

export default ChooseClientPage;